// Libs.
import React, { useEffect } from 'react';
import qs from 'querystringify';
import { get, find, sortBy } from 'lodash';
// Deps.
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import StoreListing from '../components/store/store-listing';
// State
import { useStoresStaticQueryFindStore } from '../hooks/useStoresStaticQueryFindStore';
import getWindow from '../libs/getWindow';
import navigate from '../libs/navigate';

const FindStore = () => {
  const stores = sortBy(useStoresStaticQueryFindStore(), (s) => {
    return s.name;
  });

  useEffect(() => {
    // Determine if a store_no was passed in from the 301 redirects.
    const search = getWindow('location.search') || '';
    const { store_no = '', ContentTypeId = '', ID = '' } = qs.parse(search) || {};
    if (store_no) {
      const targetStore = get(find(stores, s => s.crmId === parseInt(store_no)), 'alias', null);
      if (targetStore) {
        setTimeout(() => navigate(targetStore), 50);
      }
      // console.log('search', { search, store_no, targetStore, stores });
    } else if (ContentTypeId && ID === '51') {
      // Handle special case for offshoot url params
      setTimeout(() => navigate('/store/green-lane'), 50);
    }
  }, []);

  return (
    <Layout>
      <SEO title="Find a store"/>
      <StoreListing stores={stores}/>
    </Layout>
  );
};

export default FindStore;
