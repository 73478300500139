// Libs.
import React, { useState, useEffect } from 'react';
import * as PropTypes from 'prop-types';
// Custom Libs.
import { getInactiveStoresPoints, boostMyStore } from '../../libs/store/store-utils';
// Deps.
import StoreListingItem from './store-listing-item';
import StoreLocationMap from '../common/store/store-location-map';
import FindStoreNear from './find-store-near';
// Hooks.
import useGoogleMapsLoader from '../../hooks/useGoogleMapsLoader';
import { useDefaultStore } from '../../hooks/useDefaultStore';
// Assets.
import styles from './store-listing.module.scss';
import PagePreloader from '../common/loaders/page-preloader';

const StoreListing = ({ stores }) => {
  const googleMapsLoad = useGoogleMapsLoader();
  // My default store state.
  const { storeId, nearestStoreId } = useDefaultStore();
  const myStoreId = storeId || nearestStoreId;
  // Stores list state.
  const [storesList, setStoresList] = useState(stores);
  // Active store state.
  const [activeStoreIdx, setActiveStoreIdx] = useState(0);
  const activeStore = storesList[activeStoreIdx] || storesList[0];

  // Effect to apply my store boost relavance list to state,
  // when default store change.
  useEffect(() => {
    const storesByRelevance = boostMyStore(storesList, myStoreId);
    setStoresList(storesByRelevance);
    // Active index should point to first in the list at my store.
    setActiveStoreIdx(0);
  }, [myStoreId]);

  // Handle stores list change, triggers when reference address change.
  const handleSetStoresList = (storesList) => {
    setStoresList(storesList);
    // Expand the first item whichis the neareast store.
    setActiveStoreIdx(0);
  };

  if (!Array.isArray(storesList) || storesList.length === 0) {
    return <div>No results found.</div>;
  }
  // Stop rendering until Google Places Lib is fully loaded and my default store
  // is resolved.
  if (!googleMapsLoad) {
    return (
      <div className={styles.storeListingBackground}>
        <div className={styles.storeListingContainer}>
          <PagePreloader />
        </div>
      </div>
    );
  }

  // Prepare map active point and inactive array points.
  const activeStorePoint = { lat: activeStore.lat, lng: activeStore.lon };
  const inactiveStoresPoints = getInactiveStoresPoints(storesList, activeStoreIdx);

  return (
    <div className={styles.storeListingBackground}>
      <h1 className={styles.storeListingTitle}>Find a store</h1>
      <div className={styles.storeListingContainer}>
        <FindStoreNear storesList={storesList} setStoresList={handleSetStoresList} />
        <div className={styles.storeListingMap}>
          <StoreLocationMap
            google={window.google}
            focusPoint={activeStorePoint}
            otherPoints={inactiveStoresPoints}
            zoom={9}
          />
        </div>
        <div className={styles.storeListingList}>
          {storesList.map((store, idx) => (
            <StoreListingItem
              key={store.id}
              store={store}
              idx={idx}
              myStoreId={String(myStoreId)}
              activeStoreIdx={activeStoreIdx}
              setActive={setActiveStoreIdx}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

StoreListing.propTypes = {
  stores: PropTypes.array.isRequired,
};

export default StoreListing;
